.ticket-count-widget {
  .progress-bar {
    height: 2rem;
    width: 80%;
    border-radius: 1rem;
    margin: 0 auto;
    p {
      font-size: 1.15rem!important;
      text-align: center;
      font-weight: 500;
      line-height: 2rem;
      color: #ffffff!important;
    }
  }
  .additional-tickets {
    width: 80%;
    margin: 2rem auto 0;
    .title, .total-additional-cost {
      font-weight: 600;
      margin-bottom: 0;
    }
    .total-additional-cost {
      padding-left: 1.5rem;
    }
    .ticket-cost-breakdown-text {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .plus-sign {
      font-size: 1.75rem;
      margin-right: 0.75rem;
    }
    // accordion
    .accordion {
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23100F0D'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23100F0D'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      .accordion-button {
        border-radius: 5px;
      }
      .accordion-button:not(.collapsed), .accordion-body {
        background-color: #f7f7f5!important;
        border-radius: 5px 5px 0 0;
      }
      .accordion-body {
        border-top: 2px solid #FFFFFF;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

// Tablet + Mobile
@include media-breakpoint-down(lg) {
  .ticket-count-widget {
    .card-body {
      padding-left:0;
      padding-right: 0;
      .card-title {
        margin-left: 2rem;
        padding-bottom: 1.5rem;
        font-size: 1.35rem!important;
      }
    }
    .additional-tickets {
      width: 90%;
    }
    .progress-bar {
      width: 90%;
    }
  }
}
