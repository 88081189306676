//
// Policy Pages Styling
//
.policy-page {
  .above-fold {
    background-color: #f7f7f5;
    padding: 10rem 2rem 2.5rem;

    h1 {
      text-align: center;
      margin: 0 auto 1.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 3rem;
      margin: 0 auto 3rem;
      text-align: center;
      color: #535456;
    }
  }
  .policy-section {
    padding: 3rem;
    p, li {
      font-size: 1.25rem;
      line-height: 3rem;
      color: #535456;
    }
    ul {
      margin-left: 1rem;
    }
    .address-line {
      line-height: 1.5rem;
      margin-left: 2rem;
    }
    .email-line {
      margin-left: 2rem;
    }
  }
}

// Mobile
@include media-breakpoint-down(md) {
  .policy-page {
    .policy-section {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
