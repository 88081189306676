//
// Who We Are Page Styling
//
.who-we-are-page {
  .above-fold {
    background-color: #f7f7f5;
    padding: 15rem 2rem 10rem;

    h1 {
      text-align: center;
      margin: 0 auto 1.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 3rem;
      max-width: 90rem;
      margin: 0 auto 3rem;
      text-align: center;
      color: #535456;
    }
    .btn-primary {
      width: 16rem;
      display: block;
      margin: 0 auto;
    }
  }
  .pain-in-ass-section {
    padding: 6rem 2rem 6rem 6rem;
    .icon-text-stack {
      margin-left: 1rem;

      .click-point-img {
        height: 1.85rem;
      }
      p {
        margin-bottom: 0;
        color: #494a4b
      }
    }
    h2 {
      max-width: 40rem;
      margin-top: 3.5rem;
    }
    .right-col {
      margin-top: 1.5rem;
      padding-right: 5rem;

      p {
        font-size: 1.35rem;
        line-height: 3rem;
        color: #494a4b;
        margin-top: 2rem;
      }
    }
  }
  .entrust-section {

    .left-col {
      min-height: 55rem;
      background: url('../../../../../public/media/backgrounds/entrust.png') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .right-col {
      background-color: #1a1a1d;
      padding: 5rem 4rem 6rem 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .heart-white-img {
        height: 1.5rem;
      }
      h2 {
        color: #ffffff;
        max-width: 35rem;
        margin-bottom: 2rem;
        margin-top: 0;
      }
      p {
        color: #ffffff;
        margin-bottom: 0;
      }
      .subtitle {
        font-size: 1.5rem;
        line-height: 3rem;
        max-width: 35rem;
        margin-bottom: 2rem;
      }
      .logo-row {
          margin-top: 4rem;

          img {
              height: 3.5rem;
          }
          .clb-logo, .logo-3, .wing-logo {
              margin-left: 1rem;
          }
      }
    }
  }
  .elite-agents-section {

    .left-col {
      background-color: #ececec;
      padding: 5rem 4rem 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .heart-white-img {
        height: 1.5rem;
      }
      h2 {
        color: #100f0d;
        max-width: 40rem;
        margin-bottom: 2rem;
        margin-top: 0;
      }
      p {
        color: #535456;
        margin-bottom: 0;
      }
      .subtitle {
        font-size: 1.5rem;
        line-height: 3rem;
        max-width: 35rem;
      }
      .logo-row {
        margin-top: 5rem;
        display: flex;
        align-items: center;

        img {
            width: 6.5rem;
        }
        .clb-logo, .logo-3, .wing-logo {
            margin-left: 1rem;
        }
    }
    }
    .right-col {
      min-height: 55rem;
      background: url('../../../../../public/media/backgrounds/elite.png') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
  .daily-updates-section {
    background-color: #ffffff;
    padding: 5rem 5rem 7rem;

    h2 {
      text-align: center;
      max-width: 70rem;
      margin: 1rem auto;
      font-size: 3.5rem;
    }
    .subtext {
      color: #100f0d;
      font-size: 1.5rem;
      text-align: center;
      max-width: 80rem;
      margin: 2rem auto;
    }
    .icon-text-row {
      margin-top: 5rem;
      h4 {
        font-size: 1.75rem;
        color: #100f0d;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
      .tickets-icon, .question-icon {
        height: 4.5rem;
        margin-bottom: 1rem;
      }
      .key-icon {
        height: 4rem;
        margin-bottom: 1.5rem;
      }
      p {
        color: #1a1a1d;
        font-size: 1.5rem;
        max-width: 22rem;
      }
    }
  }
}

// Tablet
@include media-breakpoint-down(lg) {
  .who-we-are-page {
    .pain-in-ass-section {
      padding: 3rem;

      h2 {
        margin-top: 1rem;
      }
    }
    .entrust-section {
      .left-col {
        min-height: 25rem;
      }
      .right-col {
        padding-left: 4rem;
      }
    }
    .elite-agents-section {
      .right-col {
        min-height: 25rem;
      }
    }
  }
}

// Mobile
@include media-breakpoint-down(md) {
  .who-we-are-page {
    .pain-in-ass-section {
      padding: 3rem;

      .right-col {
        margin-top: 0;
        padding-right: 2rem;
      }
    }
    .entrust-section {
      .right-col {
        .logo-row {
          img {
            height: 4.5rem;
          }
          .lana-logo, .wing-logo {
            margin-top: 3rem;
          }
        }
      }
    }
    .elite-agents-section {
      .left-col {
        .logo-row {
          img {
            width: 8rem;
          }
          .google-logo, .doordash-logo {
            margin-top: 3rem;
          }
        }
      }
    }
  }
}
