//
// Dashboard Macros Snippets Page Styling
//
.macros-snippets-page {
  background-color: #f7f7f5;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  color: var(--kt-input-solid-color);
  padding-left: 1rem;
  padding-right: 1rem;

  a {
    font-weight: 500;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .macros-snippet {
    background-color: #ffffff;
    padding: 2rem 2rem 1rem 2rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }
}
