//
// Navbar Styling
//

.navbar {
  background-color: #f7f7f5;
  width: 100%;
  z-index: 100;

  .nav-link {
      margin-top: 0.75rem;
      padding-bottom: 0;
      margin-right: 2rem;
      color: #100f0dd9!important;
      font-size: 1.2rem;
  }
  .dark-icon {
      color: #100f0d;
  }
  .dropdown-menu {
    min-width: 15rem;
    border-radius: 0.25rem;
    .dropdown-item {
      height: 2.75rem;
      font-size: 1.1rem;
      line-height: 2.25rem;
      color: #100f0d;
    }
    .dropdown-item:hover {
      color: #ffffff;
    }
  }
}
.navbar > .container {
  max-width: 1500px;
  padding: 0 5rem;
}

// Tablet + Mobile
@include media-breakpoint-down(lg) {
  .navbar {
    padding: 0rem;
    height: 7.75rem;
    .container {
      background-color: #f7f7f5;
      padding: 1rem 2rem;
      .btn-primary {
        margin-top: 2.5rem;
        width: 15rem;
      }
    }
  }
  .navbar-collapse {
    margin-top: 1.5rem;
    padding-bottom: 1rem;
  }
}
