//
// Pricing Page Styling
//
.thank-you-page {
  .above-fold {
    background-color: #f7f7f5;
    padding: 15rem 2rem 0;
    text-align: center;
    height: 100%;

    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 1.75rem;
      font-weight: 400;
      color: #494a4b;
    }
    .back-home-btn {
      margin-top: 3rem;
    }
  }
}
