//
// Dashboard Billing Page Styling
//
.dash-billing-page {
  .title-tab-stack {
    justify-content: space-between;
    margin-bottom: 3rem;
    h3 {
      margin-bottom: 0!important;
    }
  }
  .card-display {
    padding-left: 1rem;
    min-height: 8.75rem;
    h4 {
      color: #100f0d;
      margin-bottom: 2rem!important;
    }
    .primary-label {
      color: #61be84;
      background-color: #bbebcc;
      width: 5rem;
      padding: 0.25rem 0.5rem;
      text-align: center;
      font-weight: 500;
      border-radius: 0.15rem;
    }
    .make-primary-label {
      border: none;
      padding: 0.25rem 0.5rem;
      color: $gray-500;
      background-color: $gray-100;
      width: 7.8rem;
    }
    .card-icon {
      width: 6rem;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    .brand-last4 {
      color: #100f0d;
      font-size: 1.25rem;
      font-weight: 500;
    }
    .card-exp {
      font-size: 1rem;
      font-weight: 500;
      color: $gray-500;
    }
    .center-col {
      display: flex;
      .vstack {
        justify-content: center;
      }
    }
    .right-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .btn {
    color: $gray-600;
    background-color: $gray-100;
  }
  .btn:hover {
    background-color: $gray-200;
  }
  .btn-primary {
    margin-top: 3rem;
    float: right;
    color: #ffffff;
    height: 4rem;
  }
  .button-stack {
    justify-content: end;
    margin-top: 2rem;
  }
  .delete-btn {
    width: 7rem;
    height: 3.5rem;
  }
  .add-new-card-stack {
    justify-content: space-between;
  }
  .error-button-stack {
    justify-content: space-between;
  }
  // Billing History
  .billing-history-card {
    padding: 1.5rem 2rem 2rem!important;
    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      .billing-title {
        color: $gray-500;
        font-size: 1rem;
        margin-bottom: 1.25rem;
      }
      .billing-value {
        color: #100f0d;
        font-size: 1.25rem;
      }
    }
    .green-status-label {
      color: #61be84;
      background-color: #bbebcc;
      padding: 0.25rem 1rem;
      text-align: center;
      font-weight: 500;
      border-radius: 0.15rem;
    }
    .gray-status-label {
      text-align: center;
      border: none;
      padding: 0.25rem 1rem;
      color: $gray-500;
      background-color: $gray-100;
      font-weight: 500;
    }
    .red-status-label {
      text-align: center;
      border: none;
      padding: 0.25rem 1rem;
      color: $red-500;
      background-color: $red-100;
      font-weight: 500;
    }
  }
}

// Tablet
@include media-breakpoint-down(lg) {
  .dash-billing-page {
    .card-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;
      padding-left: 0;
      .left-col, .center-col, .right-col {
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .right-col {
        .delete-btn {
          margin-top: 1rem;
        }
      }
    }
    .billing-history-card {
      flex-direction: row;
    }
    .btn-primary {
      float: none;
      margin: 2rem auto 0;
    }
    // Billing History
    .billing-history-card {
      .column {
        margin-bottom: 2rem;
      }
      padding-bottom: 0!important;
    }
  }
}
