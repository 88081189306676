//
// Invitation Page Styling
//
.forgot-password-page {
  .forgot-password-step {
    .done-btn {
      // display: block;
      margin: 0 auto;
    }
    .btn-light-primary {
      border-radius: 0;
      height: 4rem;
      width: 10rem;
    }
  }
}
