//
// Plan Selector Styling
//

.plan-select-form {
  h4 {
    color: #100f0d!important;
    font-size: 1.75rem;
    font-weight: 600;
  }
  .btn-outline-dashed {
      height: 10rem;
  }
  .plan-price {
    font-size: 3.5rem;
    font-weight: 600;
    color: #100f0d;
  }
  .plan-price-cycle {
    color: #8a8a89;
    margin-bottom: 0;
  }
  .feature-title {
    margin-left: 1rem;
  }
  .select-price-card {
    min-height: 5rem;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .btn-active-primary {
    background-color: #ffffff;;
  }
  .btn-active-primary.active {
    background-color: #2b2b2b!important;
    border: none;
    h2 {
      color: #ffffff;
    }
  }
  .shadow-sm {
    background-color: #ffffff!important;
    padding-top: 0.25rem!important;
    width: 35rem;
    min-height: 35rem;
  }
  .nav-group-outline {
    margin-bottom: 2rem;
  }
}
