//
// FAQ Page Styling
//
.faq-page {
  .above-fold {
    min-height: 20rem;
    background-color: #f7f7f5;
    h1 {
      margin: 10rem auto 5rem;
      text-align: center;
      max-width: 40rem;
    }
  }
}
