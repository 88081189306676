//
// What We Do Page Styling
//
.what-we-do-page {
  .above-fold {
    background-color: #f7f7f5;
    min-height: 50rem;
    padding-top: 6.5rem;

    h1 {
      text-align: center;
      margin-top: 6rem;
      margin-bottom: 3rem;
    }
    h3 {
      text-align: center;
      color: #535456;
      font-weight: 400;
      max-width: 55rem;
      margin: 0 auto 4rem;
    }
    .icon-vertical-stack {
      .icon-stack {
        justify-content: center;
        margin-bottom: 2rem;

        .skill-icon {
            height: 2.75rem;
        }
      }
    }
  }

  .provide-ecom-section {
    background-color: #ffffff;
    padding: 5rem 5rem 6rem;

    .left-col {
      h2 {
        font-size: 3.5rem;
        max-width: 37rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
        margin-left: 2rem;
      }
      p {
        color: #494a4b;
        font-size: 1.5rem;
        line-height: 3.25rem;
        max-width: 40rem;
      }
      .horizontal-stack {
        margin-top: 5rem;

        .vertical-stack-left, .vertical-stack-right {
          align-items: center;
    
          .helmet-icon {
            height: 7.5rem;
          }
          .car-icon {
            height: 5rem;
            margin: 1.25rem 0;
          }
          .da-logo {
            height: 6rem;
          }
          .gorgias-logo {
            height: 3.5rem;
            margin: 1.25rem 0;
          }
        }
        .vertical-stack-left {
          margin-right: 1rem;
        }
        .vertical-stack-right {
          margin-left: 2rem;
        }

        .vertical-divider {
          height: 15rem;
        }
      }
    }
    .right-col {
      display: flex;
      align-items: center;

      p {
        color: #494a4b;
        font-size: 1.5rem;
        line-height: 3.25rem;
        max-width: 40rem;
        margin-left: 5rem;
      }
    }
  }
  .grid-section {
    background-color: #1a1a1d;
    padding-top: 3rem;
    h2 {
      color: #ffffff;
      text-align: center;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }

    .gx-1 {
      --bs-gutter-x: 0.1rem;
    }
    p {
      color: #ffffff;
      font-size: 1.45rem;
    }
    .row-one {
      margin-bottom: 0;
    }
    .col-one, .col-two, .col-three {
      height: 30rem;

      .col-inner {
        height: 100%;
        width: 100%;
        // background-color: #1a1a1d;
        padding: 6rem 4rem 4rem;
      }
    }
    .exclamation-img {
      height: 4rem;
      width: 1rem;
      margin-bottom: 1rem;
    }
    .letter-img {
      height: 2.5rem;
      width: 4rem;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }
    .globe-img {
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;
    }
    .muscle-img {
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;
    }
    .cycle-img {
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;
    }
    .gorgias-img {
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;
    }
  }
  .specialize-d2c-section {
    padding: 3rem 5rem 5rem;
    background-color: #f7f7f5;

    h2 {
      font-size: 3.4rem;
      max-width: 35rem;
      margin-top: 1rem;
    }
    .right-col {
      p {
        color: #100f0d;
        font-size: 1.75rem;
        margin-top: 5rem;
        max-width: 45rem;
      }
    }
    .custom-table {
      margin-top: 1rem;

      .table-text {
        color: #100f0d;
        font-size: 1.5rem;
        margin-top: 0.65rem;
      }
      .first-icon {
        margin-top: -0.25rem;
        margin-right: 15.5rem;
      }
      .second-icon {
        margin-top: -0.25rem;
        margin-right: 4.5rem;
      }
      .black-check {
        width: 2.25rem;
      }
      .black-x {
        width: 1.75rem;
      }
      .top-table-row {
        border-bottom: #969696 solid 1px;
        padding-bottom: 1rem;

        .header-stack {
          margin-bottom: 1rem;
        }
        .table-header-one, .table-header-two {
          color: #100f0d;
          font-size: 1.75rem;
          font-weight: 600;
        }
        .table-header-two {
          margin-left: 8rem;
          margin-right: 0.5rem;
        }
      }
      .table-row {
        border-bottom: #969696 solid 1px;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
      }
    }
  }
  // .sorry-no-section {
  //   background-color: #1a1a1d;
  //   padding: 3rem 5rem 5rem;

  //   .hstack {
  //     justify-content: center;
  //     align-items: center;

  //     p {
  //       font-size: 1.25rem;
  //       margin-bottom: 0;
  //       margin-left: 2rem;
  //     }
  //     .point-click-icon {
  //       width: 2rem;
  //     }
  //   }
  //   h2 {
  //     color: #ffffff;
  //     text-align: center;
  //     font-size: 3.5rem;
  //   }
  //   .paragraph-text {
  //     color: #ffffff;
  //     text-align: center;
  //     max-width: 55rem;
  //     margin: 2.5rem auto 3rem;
  //   }
  //   .btn-primary {
  //     background-color: #ffffff!important;
  //     color: #1a1a1d;
  //     display: block;
  //     margin: 4rem auto 2rem;
  //   }
  // }
}

// Tablet
@include media-breakpoint-down(lg) {
  .what-we-do-page {
    .above-fold {
      .icon-vertical-stack {
        .icon-stack:nth-child(1), .icon-stack:nth-child(2) {
          // Hiding one of the icons from top row
          img:nth-child(3) {
            display: none;
          }
        }        
      }
    } 
    .provide-ecom-section {
      .right-col p {
        margin-top: 2rem;
        margin-left: 2rem;
      }
    }
    .grid-section {
      h2 {
        max-width: 50rem;
      }
      .row-one {
        margin-top: 3rem;
        margin-bottom: 0.01rem;
      }
      .col-one, .col-two, .col-three {
        height: 23rem;

        .col-inner {
          padding: 3rem 4rem;
          display: flex;
          justify-content: center;

          .vstack {
            max-width: 30rem;
            align-self: center;
          }
        }
      }
    }
    .specialize-d2c-section {
      .right-col {
        p {
          margin-top: 2rem;
          margin-bottom: 3rem;
        }
      }
      .custom-table {
        .top-table-row {
          .table-header-two {
            margin-left: 4rem;
          }
        }
        .table-text {
          max-width: 35rem;
        }
        .first-icon {
          margin-right: 11rem;
        }
      }
    }
  }
}
// Mobile
@include media-breakpoint-down(md){
  .what-we-do-page {
    .above-fold {
      padding: 5rem 1rem 3rem;
      .icon-vertical-stack {
        .icon-stack:nth-child(1) {
          // Hiding icons
          img:nth-child(1), img:nth-child(3), img:nth-child(4) {
            display: none;
          }
        }
        .icon-stack:nth-child(2) {
          img:nth-child(1) {
            display: none;
          }
        }
        .icon-stack:nth-child(3) {
          img:nth-child(2) {
            display: none;
          }
        }
      }
    }
    .provide-ecom-section {
      padding: 3rem 2rem;

      .left-col {
        h2 {
          margin-left: 0;
          font-size: 3rem;
        }
        .horizontal-stack {
          flex-direction: column;
          .vertical-stack-right {
            margin-left: 0;
            margin-top: 2rem;
          }
        }
        .vertical-divider {
          display: none;
        }
      }
      .right-col {
        p {
          margin-left: 0;
        }
      }
    }
    .grid-section {
      h2 {
        font-size: 3rem;
      }
    }
    .specialize-d2c-section {
      padding-left: 2rem;
      padding-right: 2rem;

      h2 {
        font-size: 3rem;
      }
      .custom-table {
        .table-text {
          font-size: 1.25rem;
          max-width: 12.5rem;
        }
        .top-table-row {
          .header-stack {
            .table-header-one, .table-header-two {
              font-size: 1.25rem;
            }
            .table-header-two {
              margin-left: 1rem;
            }
          }
          .second-icon {
            margin-right: 2.75rem;
          }
        }
        .first-icon {
          margin-right: 6rem;
        }
        .second-icon {
          margin-right: 2.75rem;
        }
      }
    }
  }
}
