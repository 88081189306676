//
// Dashboard Custom Styling
//
#kt_wrapper {
  background-color: #f7f7f5;
  #kt_header {
    background-color: #ffffff;
  }
  #kt_content {
    margin-top: -4.25rem;
    padding: 1rem;
    #kt_content_container {
      padding: 0;
      max-width: 100%;
      // TODO: will prob need to move this to a seprate file
      .temp-dash-page {
        background-color: #f7f7f5;
        .left-col {
          max-height: 40rem;
          padding-left: 3rem;
          justify-items: center;
          h2 {
            font-size: 1.5rem;
            color: #1a1a1d;
          }
          p, ul {
            color: #1a1a1d;
            font-size: 1.25rem;
          }
          a {
            font-weight: 600;
          }
        }
        .right-col {
          display: flex;
          align-items: center;
          max-height: 40rem;
          .agent-desk-illustration {
            width: 100%;
            max-width: 50rem;
          }
        }
        
      }
    }
  }
  #kt_header_menu_mobile_toggle {
    // TODO; remove at some point
    display: none;
  }
}

// Tablet
@include media-breakpoint-down(lg) {
  #kt_wrapper {
    #kt_content {
      #kt_content_container {
        .temp-dash-page {
          .row {
            flex-direction: column-reverse!important;
            .right-col {
              .agent-desk-illustration {
                display: block;
                width: 35rem;
                margin: 0 auto 1rem;
              }
            }
          }
        }
      }
    }
  }
}

// Mobile
@include media-breakpoint-down(md) {
  #kt_wrapper {
    #kt_content {
      overflow-y: hidden;
      #kt_content_container {
        .temp-dash-page {
          .row {
            .left-col {
              max-height: unset;
              padding-bottom: 3rem;
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .right-col {
              .agent-desk-illustration {
                width: 25rem;
              }
            }
          }
        }
      }
    }
  }
}
