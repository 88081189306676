//
// Homepage Styling
//
.home-page {
  .home-above-fold {
    background-color: #f7f7f5;
    min-height: 61.55rem;
    padding-top: 6.5rem;

    .left-col {
      padding: 5rem 3rem 3rem 5rem;
      width: 46rem;
      position: absolute;
      z-index: 2;
      background-color: #f7f7f5;
      height: 55rem;

      .left-col-inner {
        display: block;
        margin: 0 auto;
        max-width: 40rem;
        height: 100%;

        .gorgias-text {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 5rem;

          .gorgias-logo {
            width: 10rem;
            display: block;
            bottom: 0;
          }
          p {
            font-size: 1.25rem;
            color: #100f0d;
            margin-left: 2rem;
            margin-top: 1.25rem;
          }
        }
        .shopify-partner-logo {
          width: 15rem;
          margin-top: 1rem;
        }
      }
    }
    .right-col {
      height: 100%;
      background-color: #f7f7f5;

      #video {
        position: absolute;
        z-index: 0;
        right: 0;
        height: 55rem;
      }
      .blue-curve-bottom {
        position: absolute;
        top: 51rem;
        right: 3rem;
        z-index: 1;
      }
      .blue-curve-top {
        position: absolute;
        top: 10rem;
        z-index: 1;
      }
    }
  }

  // .what-we-do-section {
  //   background-color: #ffffff;
  //   padding: 10rem 5rem;
  //   min-height: 55rem;

  //   .left-col {

  //     .left-col-inner {

  //       p {
  //         color: #adadab;
  //         font-size: 1.55rem;
  //       }
  //       .paragraph-text {
  //         color: #100f0d;
  //       }
  //     }
  //     .btn-primary {
  //       margin-top: 2.25rem;
  //     }
  //   }
  //   .right-col {
  //     padding: 0 3rem;
  //     display: flex;

  //     .right-col-inner {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       justify-content: center;
  //       min-height: 35rem;

  //       .paragraph-text {
  //         margin-top: 0;
  //       }
  //     }
  //   }
  // }
  .firstline-section {
    background-color: #ffffff;
    padding: 2.5rem 5rem 7rem;
    h2 {
      text-align: center;
      max-width: 70rem;
      margin: 1rem auto;
      font-size: 3.5rem;
    }
    .subtext {
      color: #100f0d;
      font-size: 1.5rem;
      text-align: center;
      max-width: 80rem;
      margin: 2rem auto;
    }
    .icon-text-row {
      h3 {
        margin-top: 4rem;
        margin-bottom: 4rem;
        font-size: 2rem;
      }
      h4 {
        font-size: 1.75rem;
        color: #100f0d;
        max-width: 13rem;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
      .chatbubbles-icon, .convo-icon, .location-icon {
        height: 4rem;
        margin-bottom: 1rem;
      }
      .upwards-icon {
        height: 2.75rem;
        margin-top: 0.75rem;
        margin-bottom: 1.5rem;
      }
      p {
        color: #1a1a1d;
        font-size: 1.5rem;
        max-width: 18rem;
      }
    }
  }
  .get-started-section {
    padding: 3rem 5rem 5rem;
    background-color: #1a1a1d;

    .left-col {
      .arrow-line-img {
        height: 10rem;
        margin-bottom: 2rem;
      }
      .arrow-img {
        width: 2.7rem;
        margin-top: -7.75rem;
        margin-left: -0.1rem;
      }
      h2 {
        font-size: 2.5rem;
        margin-bottom: 4rem;
        color: #ffffff;
      }
      .text-stack {
        margin-left: 2rem;
        margin-top: 0.5rem;
    
        h4 {
          color: #ffffff;
        }
        p {
          color: #b9b8b7;
          font-size: 1.45rem;
          max-width: 45rem;
        }
      }
    }
    .right-col {
      h4 {
        color: #ffffff;
        font-size: 1.75rem;
        margin-top: 2rem;;
      }
      p {
        color: #767777;
        font-size: 1.25rem;
      }
      .avatar-circle-img {
        width: 12rem;
        margin-right: 2rem;
      }
      .expert-skills-img {
        height: 2.25rem;
      }
      .agent-stack-1 {
        margin-top: 8rem;
      }
      .agent-stack-2 {
        margin-top: 7rem;
        margin-left: 5rem;
      }
      .agent-stack-3 {
        margin-top: 7rem;
      }
    }
  }
  .trusted-clients-section {
    padding: 3rem 5rem 8rem;
    background-color: #f7f7f5;

    h2 {
      text-align: center;
      font-size: 3.5rem;
    }
    .review-row {
      margin-top: 8rem;

      .review-wrapper {
        width: 100%;
        border: 1px solid #c1c3c3;
        border-radius: 1rem;
        padding: 3rem;
        min-height: 32rem;

        .img-text-stack {
          align-items: flex-start;
          .avatar-circle-img {
            width: 6rem;
          }
          h3 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.75rem;
            margin-top: 0.5rem;
          }
        }
        p {
          font-size: 1.35rem;
          color: #535456;
          margin-top: 2rem;
        }
        .five-stars-img {
          height: 1.25rem;
          width: 7.5rem;
        }
      }
    }
    .btn-primary {
      display: block;
      margin: 2rem auto 0;
      width: 14rem;
    }
  }
  .cost-effective-section {
    background-color: #ffffff;
    padding: 3rem 5rem 5rem;

    h2 {
      color: #1a1a1d;
      max-width: 46.95rem;
      margin: 1.75rem auto;
    }
    ul {
      margin-left: 6rem;
    }
    li {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #1a1a1d;
    }
    .subtitle {
      font-size: 1.6rem;
      text-align: center;
      max-width: 70rem;
      margin: 0 auto 7rem;
      color: #1a1a1d;
    }

    .starting-at-stack {
      margin-left: 10rem;
      h3 {
        color: #1a1a1d;
        font-size: 2.25rem;
      }
      .price {
        font-size: 5.5rem;
      }
      .per-month {
        font-size: 1.35rem;
        font-weight: 400;
        margin-top: 2rem;
      }
    }
    .btn-primary {
      color: #ffffff;
      background-color: #1a1a1d!important;
      width: 14rem;
      margin: 6rem auto 0;
    }
  }
  // TODO: may need to calculate these dynamically according to vw
  @media screen and (max-width: 1429px) {
    // adjusting top blue curve
    .home-above-fold {
      .right-col {
        .blue-curve-top {
          left: 49rem;
        }
      }
    }
  }
  @media screen and (min-width: 1430px) {
    // adjusting top blue curve
    .home-above-fold {
      .right-col {
        .blue-curve-top {
          left: auto;
          right: 48.5rem;
        }
      }
    }
  }

  // Tablet
  @include media-breakpoint-down(lg) {
    .home-above-fold {
      min-height: 68rem;
      .left-col {
        width: 35rem;
        height: 61.5rem;
      }
      .left-col {
        .left-col-inner {
          .gorgias-text {
            margin-top: 1rem;
          }
        }
      }
      .right-col {
        .blue-curve-top {
          top: 12.5rem;
          left: 37rem;
          // display: none;
        }
        .blue-curve-bottom {
          top: 57rem;
          right: 2rem;
        }
        #video {
          height: 61.5rem;
          right: calc((815px - 55vw) * -1);
        }
      }
    }
    .firstline-section {
      margin-top: 5rem;

      .icon-text-row {
        p {
          margin-bottom: 3rem;
        }
      }
    }

    // .what-we-do-section {
    //   padding-bottom: 0;

    //   .right-col {
    //     padding: 0 1rem;
    //   }
    // }

    // .grid-section {
    //   .row-one {
    //     margin-bottom: 0.01rem;
    //   }
    //   .col-one, .col-two, .col-three {
    //     height: 23rem;

    //     .col-inner {
    //       padding: 3rem 4rem;
    //       display: flex;
    //       justify-content: center;

    //       .vstack {
    //         max-width: 30rem;
    //         align-self: center;
    //       }
    //     }
    //   }
    // }

    .get-started-section {
      .right-col {
        .agent-stack-1 {
          margin-top: 5rem;
        }
        .agent-stack-2 {
          margin-left: 12.5rem;
        }
      }
    }
    .trusted-clients-section {
      .review-row {
        .review-wrapper {
          margin-bottom: 2.5rem;
          min-height: 20rem;
        }
      }
    }

    // .specialize-d2c-section {
    //   .right-col {
    //     p {
    //       margin-top: 2rem;
    //       margin-bottom: 2rem;
    //     }
    //   }
    // }
    .cost-effective-section {
      .subtitle {
        margin-bottom: 3rem;
      }
      .starting-at-stack {
        width: 23rem;
        margin: 0 auto 2.5rem;

        h3 {
          margin: 0 auto;
        }
        .price {
          margin-top: 2rem;
        }
      }
    }
  }

  // Mobile
  @include media-breakpoint-down(md) {
    .home-above-fold {
      min-height: 82.5rem;
      margin-top: 3.25rem;

      .left-col {
        position: relative;
        padding: 2rem 3rem;
        height: unset;
        .left-col-inner {
          h1 {
            margin-top: 1rem;
            font-size: 2.75rem;
          }
          .gorgias-text {
            margin-top: 2rem;
          }
        }
      }
      // TODO: should calculate the blue curves based on video height
      .right-col {
        .blue-curve-top {
          top: 9.5rem;
          left: 1.75rem;
          width: 5rem;
        }
        .blue-curve-bottom {
          top: 30.5rem;
          right: 1.75rem;
          width: 5rem;
        }
        #video {
          position: relative;
          right: auto;
          height: auto;
          width: 100%;
          margin-top: -2rem;
        }
      }
    }
    .firstline-section {
      margin-top: 0;
      padding: 2rem;

      h2 {
        font-size: 3rem;
      }
      .icon-text-row {
        text-align: center;
       

        h4, p {
          max-width: 25rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .subtext {
        margin-bottom: 0;
      }
    }
    // .what-we-do-section {
    //   padding: 4rem 3rem 0;
    //   height: auto;

    //   .left-col {
    //     h2 {
    //       font-size: 2.5rem;
    //     }
    //     .btn-primary {
    //       margin-top: 1rem;
    //       margin-bottom: 3rem;
    //     }
    //   }

    //   .right-col {
    //     padding: 1rem 1rem 2rem;

    //     .right-col-inner {
    //       min-height: auto;
    //       justify-content: flex-start;
    //     }
    //   }
    // }

    .get-started-section {
      padding: 1rem 2rem 4rem;
      .left-col {
        .arrow-line-img {
          margin-top: 1rem;
        }
        .arrow-img {
          margin-top: -7rem;
        }
        .text-stack  {
          h4 {
            font-size: 1.5rem;
          }
          p {
            font-size: 1.3rem;
          }
        }
      }
      .right-col {
        .agent-stack-2 {
          margin-left: 0;
        }
        .icon-stack {
          flex-direction: column;
          align-items: flex-start;
        }
        .text-icon-stack {
          margin-top: -1.5rem;
        }
      }
    }

    // .specialize-d2c-section {
    //   padding: 2rem 2rem 4rem;
    //   h2 {
    //     font-size: 2.75rem;
    //   }
    //   .custom-table {
    //     .first-icon {
    //       margin-right: 6rem;
    //     }
    //     .second-icon {
    //       margin-right: 2.5rem;
    //     }
    //     .top-table-row {
    //       .table-header-one, .table-header-two {
    //         font-size: 1.2rem;
    //       }
    //       .table-header-two {
    //         margin-left: 2rem;
    //         margin-right: 0;
    //       }
    //       .first-icon {
    //         margin-right: 6.5rem;
    //       }
    //       .second-icon {
    //         margin-right: 2.5rem;
    //       }
    //     }
    //     .last-row {
    //       .first-icon {
    //         margin-right: 6.5rem;
    //       }
    //     }
    //   }
    // }
    .trusted-clients-section {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 5rem;
      h2 {
        font-size: 3rem;
      }
      .review-row {
        margin-top: 4rem;
      }
    }
    .cost-effective-section {
      padding: 2rem 2rem 4rem;

      h2 {
        font-size: 3rem;
        text-align: center;
      }
      ul {
        margin-left: 0;
      }
    }
  }
}
