//
// AI Page Styling
//
.ai-page {
  padding: 0 1rem 1rem;
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .bold-text {
    font-weight: 600;
  }
  // Buttons
  .btn-salmon {
    background-color: #ee615e!important;
    color: #ffffff;
    font-weight: 500;
  }
  .btn-salmon:hover {
    border: 2px solid #ee615e!important;
    background-color: #ffffff!important;
    color: #ee615e;
  }
  // AI Launch Section
  .ai-launch-section {
    background-color: #ffffff;
    width: 100%;
    padding: 1rem;
    margin: 0;
    .rocket-img {
      width: 8rem;
    }
    .launch-col {
      display: flex;
      align-items: center;
      justify-content: center;
      .launch-text {
        color: #100f0d;
        font-size: 1.25rem;
        margin: 0;
      }
    }
    .launch-sequence-col {
      display: flex;
      justify-content: center;
      align-items: center;
      .launch-sequence-text {
        color: #ee615e;
        // text-shadow: 2px 1px 1px #ee615e;
        text-shadow: 2px 2px 1px #3b4cd0;
        // color: #3b4cd0;
        font-size: 3.5rem;
        text-align: center;
        margin: 0;
        margin-left: -20%;
        opacity: 1;
        animation: fade 1s 3;
      }
      @keyframes fade {
        0%   { opacity:0; }
        50%  { opacity:1; }
        100% { opacity:0; }
      }
      @-moz-keyframes fade {
        0%   { opacity:0; }
        50%  { opacity:1; }
        100% { opacity:0; }
      }
      @-webkit-keyframes fade {
        0%   { opacity:0; }
        50%  { opacity:1; }
        100% { opacity:0; }
      }
      @-o-keyframes fade {
        0%   { opacity:0; }
        50%  { opacity:1; }
        100% { opacity:0; }
      }
      @-ms-keyframes fade {
        0%   { opacity:0; }
        50%  { opacity:1; }
        100% { opacity:0; }
      }
    }
  }
  // TODO: really should to pull out common css
  .doc-upload-section {
    margin: 0;
    .doc-upload-col {
      padding: 0;
    }
    .nav-tabs {
      border: none;
      .nav-link {
        font-weight: 600;
        background-color: #ffffff;
        border-color: #f7f7f5;
        color: var(--bs-nav-tabs-link-active-color)
      }
      .tab-1.active {
        background-color: #ee615e;
        color: #ffffff;
      }
      .tab-2.active {
        background-color: #3b4cd0;
        color: #ffffff;
      }
    }
    .tab-content, .tab-pane {
      background-color: #ffffff;
      padding: 1rem 1rem 0.5rem;
      .upload-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .button-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
      }
    }
    .tab-pane label {
      color: #100f0d!important;
    }
    .qa-format-stack {
      margin-top: 1rem;
      .qa-format {
        accent-color: #3b4cd0;
      }
    }
  }
  .select-file {
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #100f0d;
    width: 12.5rem!important;
    height: 4rem;
    line-height: 2.5rem;
    border: none;
    --bs-btn-border-radius: 0.1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    --kt-primary-active: #100f0d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type='file'] {
    color: transparent;
  }
  .selected-file {
    color: #100f0d;
    margin: 0;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .selected-file-stack {
    align-self: center;
    justify-content: center;
    margin-top: 1rem;
    .close-icon {
      width: 2rem;
      cursor: pointer;
    }
  }
  // Company Doc List Section
  .company-docs-list {
    margin-top: 1.5rem;
    .doc-title-row {
      background-color: #ffffff;
      margin: 0 0 1.25rem;
      padding: 1rem 0.25rem;
      .doc-title {
        color: #100f0d;
        margin-bottom: 0;
        font-weight: 600;
        align-items: center;
      }
    }
    .company-doc-item {
      width: 100%;
      height: 8rem;
      margin-bottom: 1rem;
      background-color: #ffffff;
      .doc-item-row {
        height: 100%;
        .doc-item-col {
          height: 100%;
          display: flex;
          align-items: center;
        }
        .del-col {
          justify-content: end;
        }
      }
      .btn-blue {
        background-color: #3b4cd0!important;
      }
      .btn-blue:hover {
        border: 2px solid #3b4cd0!important;
        background-color: #ffffff!important;
        color: #3b4cd0;
      }
      .btn-salmon-light {
        background-color: #ffffff!important;
        color:  #ee615e;
      }
      .btn-salmon-light:hover {
        border: 2px solid #ee615e!important;
        background-color: #ffffff!important;
      }
      .btn-blue-light {
        background-color: #ffffff!important;
        color: #3b4cd0;
      }
      .btn-blue-light:hover {
        border: 2px solid #3b4cd0!important;
        background-color: #ffffff!important;
      }
    }
    .salmon-doc-item {
      box-shadow: 0 0 2px #ee615e;
      -moz-box-shadow: 0 0 2px #ee615e;
      -webkit-box-shadow: 0 0 2px #ee615e;
      padding: 1rem;
    }
    .blue-doc-item {
      box-shadow: 0 0 2px #3b4cd0;
      -moz-box-shadow: 0 0 2px #3b4cd0;
      -webkit-box-shadow: 0 0 2px #3b4cd0;
      padding: 1rem;
    }
    .salmon-text {
      color: #ee615e;
      margin-bottom: 0;
      font-size: 1.1rem;
    }
    .blue-text {
      color: #3b4cd0;
      margin-bottom: 0;
      font-size: 1.1rem;
    }
    .salmon-text, .blue-text {
      font-weight: 700;
    }
    .doc-item-text {
      color: #100f0d;
      margin-bottom: 0;
      font-weight: 500;
    }

    .delete-modal {
      width: 30rem;
      background-color: #ffffff;
      border: 1px solid #100f0d;
      position: fixed;
      top: 25%;
      right: 5%;
      padding: 1rem;
      h2 {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 2rem;
      }
      p {
        color: #100f0d;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 2rem;
      }
      .bold-text {
        font-weight: 600;
      }
      .btn-salmon {
        background-color: #ee615e!important;
        margin: 0 auto 1.5rem;
      }
      .btn-salmon:hover {
        border: 2px solid #ee615e!important;
        background-color: #ffffff!important;
        color: #ee615e;
      }
      .close-icon {
        cursor: pointer;
      }
    }
  }
}
