//
// What's Included Page Styling
//
.whats-included-page {
  .above-fold {
    background-color: #f7f7f5;
    padding: 15rem 2rem 10rem;

    h1 {
      text-align: center;
      margin: 0 auto 1.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 3rem;
      max-width: 90rem;
      margin: 0 auto 3rem;
      text-align: center;
      color: #535456;
    }
    .btn-primary {
      width: 16rem;
      display: block;
      margin: 0 auto;
    }
  }
  .out-the-box-section {
    padding: 6rem 2rem 6rem 6rem;
    h2 {
      max-width: 30rem;
      margin-top: 3.5rem;
    }
    .right-col {
      margin-top: 1.5rem;

      p {
        font-size: 1.35rem;
        line-height: 3rem;
        color: #494a4b;
        margin-top: 2rem;
      }
    }
  }
  .synchronize-all-section {

    .left-col {
      min-height: 55rem;
      background: url('../../../../../public/media/backgrounds/synch-all.png') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .right-col {
      background-color: #1a1a1d;
      padding: 5rem 4rem 6rem 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .heart-white-img {
        height: 1.5rem;
      }
      h2 {
        color: #ffffff;
        max-width: 35rem;
        margin-bottom: 2rem;
        margin-top: 0;
      }
      p {
        color: #ffffff;
        margin-bottom: 0;
      }
      .subtitle {
        font-size: 1.5rem;
        line-height: 3rem;
        max-width: 35rem;
      }
      .btn-primary {
        background-color: #ffffff!important;
        color: #1a1a1d;
        margin-top: 4rem;
        width: 15rem;
      }
    }
  }
  .streamline-section {

    .left-col {
      background-color: #ececec;
      padding: 5rem 4rem 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .heart-white-img {
        height: 1.5rem;
      }
      h2 {
        color: #100f0d;
        max-width: 40rem;
        margin-bottom: 2rem;
        margin-top: 0;
      }
      p {
        color: #535456;
        margin-bottom: 0;
      }
      .subtitle {
        font-size: 1.5rem;
        line-height: 3rem;
        max-width: 35rem;
      }
      .btn-primary {
        background-color: #100f0d!important;
        color: #ffffff;
        margin-top: 4rem;
        width: 15rem;
      }
    }
    .right-col {
      min-height: 55rem;
      background: url('../../../../../public/media/backgrounds/streamlining.png') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
  .daily-updates-section {
    background-color: #ffffff;
    padding: 5rem 5rem 7rem;

    h2 {
      text-align: center;
      max-width: 70rem;
      margin: 1rem auto;
      font-size: 3.5rem;
    }
    .subtext {
      color: #100f0d;
      font-size: 1.5rem;
      text-align: center;
      max-width: 80rem;
      margin: 2rem auto;
    }
    .icon-text-row {
      margin-top: 5rem;
      h4 {
        font-size: 1.75rem;
        color: #100f0d;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
      .tickets-icon, .question-icon {
        height: 4.5rem;
        margin-bottom: 1rem;
      }
      .key-icon {
        height: 4rem;
        margin-bottom: 1.5rem;
      }
      p {
        color: #1a1a1d;
        font-size: 1.5rem;
        max-width: 22rem;
      }
    }
  }
}

// Tablet
@include media-breakpoint-down(lg) {
  .whats-included-page {
    .out-the-box-section {
      padding-top: 2rem;
  
      .right-col {
        margin-top: 0;
      }
    }
  
    .synchronize-all-section {
      .left-col {
        min-height: 25rem;
      }
    }
    .streamline-section {
      .right-col {
        min-height: 25rem;
      }
    }
    .daily-updates-section {
      .icon-text-row {
        .question-icon {
          margin-top: 2rem;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .whats-included-page {
    .out-the-box-section {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .synchronize-all-section {
      .right-col {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
    .streamline-section {
      .left-col {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
    .daily-updates-section {
      padding-left: 3rem;
      padding-right: 3rem;

      h2 {
        text-align: left;
      }
      .subtext {
        text-align: left;
        margin-bottom: 0;
      }
      .icon-text-row {
        margin-top: 3rem;
        .key-icon {
          margin-top: 2rem;
        }
      }
    }
  }
}
