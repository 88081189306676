//
// Charge Price Component Styling
//
.charge-price-breakdown-container {
  margin: 2rem auto 3.5rem 0;
  h3 {
    margin-top: 1rem;
  }
  .left-col {
    padding-right: 2rem;
  }
  .pricing-stack-row {
    justify-content: space-between;
    border-bottom: dashed 1px #a9a9a9;
    height: 5rem;
  }
  .last-row {
    border: none;
  }
  .detail-text {
    color: #100f0d;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .amount-text {
    color: #100f0d;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .total-detail-text {
    font-size: 1.5rem;
  }
  .total-detail-amount {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .right-col {
    h3 {
      margin-bottom: 2rem;
    }
    .input-container {
      display: flex;
      margin-top: 2rem;
      input {
        height: 4.75rem;
        border-radius: 5px 0 0 5px;
        box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
        border: 1px solid #d9dee1!important;
      }
      .btn-primary {
        height: 4.75rem;
        border-radius: 0 5px 5px 0 ;
      }
    }
  }
}
