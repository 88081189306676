//
// Onboarding Dot
//

// Base
.onboarding-dot {
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 0.3rem;
    background-color: #ee615e;
    margin-left: 0.5rem;
    margin-top: -1rem;
}
