//
// AccountHeader Custom Styling
//
.account-page-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;

  .company-logo:hover {
    cursor: pointer;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
  p {
    margin-bottom: 0;
  }
  .card-title {
    h3 {
      font-weight: 600!important;
      margin-bottom: 2rem!important;
    }
  }
  input, select {
    background-color: #f1f1f1!important;
    border: none!important;
  }
  .card-footer {
    border-top: none;
    padding-bottom: 0!important;
    padding-top: 1rem!important;
  }
}
// Tablet
@include media-breakpoint-down(lg) {
  .account-page-wrapper {
    padding: 2rem;
  }
}
// Mobile
@include media-breakpoint-down(md) {
  h3 {
    text-align: center;
  }
  .account-page-wrapper {
    padding: 1rem;
    .company-logo {
      margin-bottom: 1.5rem;
    }
    .plan-selector-card {
      padding: 3rem 1rem!important;
    }
  }
  #kt_signin_email {
    margin-bottom: 2rem;
  }
}
