//
// Invitation Page Styling
//
.team-page {
  padding-left: 1rem;
  padding-right: 1rem;

  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  input, select {
    background-color: #f1f1f1!important;
    border: none;
  }
  .invitation-section {
    background-color: #ffffff;
    padding: 2rem 1.5rem 1rem;
    margin: 0;

    .invitation-row {
      .right-inner-stack {
        width: 60%;
        .email-input-wrapper {
          width: 100%;
        }
      }
      button {
        float: right!important;
        margin-right: 1rem;
        min-width: unset!important;
      }
    }
  }
  .member-list-section {
    background-color: #ffffff;
    margin: 1.5rem 0rem 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .member-item {
      height: 5.5rem;
      border-bottom: 1px solid #dededc;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      align-items: center;

      .member-item-row {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .button-stack {
          float: right;
          width: 18.5rem;
          .btn-primary {
            color: #ffffff;
            min-width: 10rem;
          }
          .btn {
            background-color: $gray-400;
            color: #ffffff;
            height: 4rem;
            border-radius: 0;
            font-weight: 500!important;
            font-size: 1.2rem;
          }
        }
      }

      .member-name, .member-owner, .member-status, .member-email, .member-invite-date {
        color: var(--kt-text-gray-600);
        font-size: 1.25rem;
        margin-bottom: 0;
        width: 12rem;
      }
      .member-name, .member-owner, .member-status, .member-invite-date {
        text-transform: capitalize;
      }
      .member-owner, .member-status {
        width: 6rem;
        padding: 0.25rem 0.5rem;
        text-align: center;
        font-size: 1.1rem;
        font-weight: 500;
        border-radius: 0.15rem;
      }
      .member-status-active {
        color: #61be84;
        background-color: #bbebcc;
      }
      .member-status-pending {
        color: #FFDE00;
        background-color: #FFFCC9;
      }
      .member-owner-owner {
        color: #ffffff;
        background-color: #dadbdb;
      }
      .member-owner-member {
        border: 1px solid #ebebeb;
        color: #a9aaab;
      }
    }
  }
}


// Tablet
@include media-breakpoint-down(lg) {
  .team-page {
    .invitation-section {
      .name-input {
        margin-bottom: 1.5rem;
      }
      .email-input {
        margin-bottom: 2rem;
      }
    }
    .member-list-section {
      .member-item {
        height: 12rem;
        .member-item-row {
          .button-stack {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

// Mobile
@include media-breakpoint-down(md) {
  .team-page {
    .member-list-section {
      .member-item {
        height: unset;
        padding-top: 2rem;
        padding-bottom: 1rem;
        .member-item-row {
          .button-stack {
            float: left;
          }
          .col-md-4 {
            margin-bottom: 1.25rem;
          }
        }
      }
    }
  }
}
