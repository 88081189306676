//
// Dashboard Macros Settings Page Styling
//
.macros-settings-page {
  background-color: #f7f7f5!important;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;

  h1 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .macros-button {
    position: fixed;
    z-index: 100;
    top: 0.4rem;
  }

  .question-container {
    background-color: #ffffff;
    padding: 0 2rem 2rem;
    margin-bottom: 1.25rem;

    .question {
      color: #100f0d;
      margin-bottom: 1rem;
    }

    .multiselect-option-container {
      display: flex;
      margin-bottom: 1rem;
      .multiselect-container {
        color: #100f0d;
        padding-left: 1rem;
        font-size: 1.1rem;
      }
    }
    .form-select {
      border: 1px solid #dededc!important;
    }
    .col-form-label {
      color: #100f0d;
      margin-top: 1rem;
      width: 100%;
    }
    .select-all-text {
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
    .timeframe-container {
      margin-top: 1.5rem;

      #timeframe-period {
        margin-left: 1rem;
      }
      #timeframe-amount, #timeframe-period {
        height: 3rem;
        border: 1px solid #dededc;
        border-radius: 0.55rem;
        padding-left: 1rem;
        font-size: 1.1rem;
        color: var(--kt-input-solid-color);
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../arr072.svg') no-repeat #fff;
        background-size: 1.25rem;
        background-position: right 1rem center;
        padding-right: 3rem;
      }
    }
    .other-input {
      margin-top: 1.5rem;
      padding-left: 1.5rem;
      height: 3.5rem;
      border: 1px solid #dededc;
      border-radius: 0.55rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--kt-input-solid-color);
    }
  }
  .bottom-sticky {
    width: 100%;
    height: 5rem;
    background-color: #ffffff;
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;

    .macros-button-mobile {
      z-index: 300;
      margin: 0.5rem auto;
    }
  }
}
