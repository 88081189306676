//
// FAQ Component Styling
//
.faq-section {
    background-color: #ffffff;
    padding: 3rem 5rem 5rem;
  
    .section-title {
      text-align: center;
      margin-bottom: 4rem;
    }
    a {
      font-size: 1.25rem;
      color: #1a1a1d;
      display: block;
      width: 5rem;
      margin: 2rem auto 0;
    }
    .inline-link {
      width: unset;
      margin: 0;
      display: inline;
      color: #3eb2b5;
    }
    .accordion {
      margin: 0 auto 1rem;
      max-width: 70rem;
      --bs-accordion-btn-bg: #ffffff;
      --bs-accordion-btn-color: #100f0d;
      --bs-accordion-btn-icon: var(--kt-accordion-button-icon-light);
      --bs-accordion-border-color: none;
      border-top: 1px solid #898b89;
  
      .accordion-header {
        margin-top: 0;
        border-bottom: 1px solid #898b89;
      }
      .question-text, .answer-text {
        margin-top: 1rem;
        font-size: 1.25rem;
        font-weight: 500;
      }
      .answer-text {
        color: #444544;
      }
      .accordion-item {
        border: 1px solid #898b89;
        border-bottom: none;
        border-top: none;
  
        .accordion-button {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
        .accordion-button:not(.collapsed) {
          background-color: #ffffff;
          color: #100f0d;
        }
      }
      .accordion-body {
        color: #100f0d;
        border-bottom: 1px solid #898b89;
      }
    }
  }

// Mobile
@include media-breakpoint-down(md){
  .faq-section {
    padding-left: 2rem;
    padding-right: 2rem;
    
    h2 {
      font-size: 3rem;
    }
  }
}
