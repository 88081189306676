//
// Admin Dashboard Styling
//

.admin-company-page, .admin-user-page, .admin-users-list-page, .admin-companies-list-page  {
  padding: 1rem;
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }
  h2 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  h3 {
    font-size: 1.25rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  p {
    color: #100f0d;
    margin-bottom: 0;
  }
  .row {
    width: 100%;
  }
  .col {
    padding: 0;
  }
  .card-body {
    padding: 2rem;
    margin-bottom: 1.5rem;
  }



  // TODO: refactor these below to cover both admin & customer dash
  .member-owner {
    width: 6rem;
    margin-top: 0;
    padding: 0.25rem 0.5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.15rem;
  }
  .member-owner-owner {
    color: #ffffff;
    background-color: #dadbdb;
  }
  .member-owner-member {
    border: 1px solid $gray-300;
    color: #a9aaab;
  }
  .member-status {
    width: 6rem;
    padding: 0.25rem 0.5rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 0.15rem;
    text-transform: capitalize;
  }
  .member-status-active {
    color: #61be84;
    background-color: #bbebcc;
  }
  .member-status-pending {
    color: #FFDE00;
    background-color: #FFFCC9;
  }




  .username-stack {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
  }
  .team-member-item {
    height: 6rem;
  }
  .item-column {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    .arrow-icon {
      height: 1.5rem;
    }
  }
  .arrow-column {
    justify-content: end;
    padding-right: 0;
    .arrow-icon {
      height: 1.5rem;
      margin-right: -1rem;
    }
  }
  .team-member-item:hover, .company-item:hover  {
    background-color: $gray-200;
  }
  .company-item {
    height: 6rem;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
  }
  .list-section {
    margin-left: 0;
    margin-right: 0;
  }
  .list-header {
    background-color: #ffffff;
    padding: 1rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .macros-snippet {
    background-color: #ffffff;
    padding: 2rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    .copy-icon, .copied-text {
      position: absolute;
      right: 4rem;
    }
    .copied-text {
      font-size: 0.9rem;
      color: gray;
    }
  }
}
