//
// Company Setup Wizard Custom Styling
//
#kt_create_account_stepper {
  padding-top: 0!important;
}
.main-wizard-panel {
  width: 80rem;
  .wizard-title {
    font-size: 3rem;
  }
  .stepper-title {
    color: #474761!important;
  }
  .card-body {
    padding-bottom: 0;
    #kt_create_account_form {
      padding-top: 0!important;
      .back-button {
        padding: 1.15rem 4rem 1rem;
        border-radius: unset;
        font-size: 1.25rem;
        background-color: #100f0d;
        color: #ffffff;
        .svg-icon {
          color: #ffffff;
        }
      }
      .back-button:hover:not(.btn-active) {
        background-color: #4855ae!important;
      }
    }
  }
}

// Tablet
@include media-breakpoint-down(lg){
  .main-wizard-panel {
    width: unset;
    .shadow-sm {
      width: unset!important;
    }
  }
}

// Mobile
@include media-breakpoint-down(md) {
  .main-wizard-panel {
    padding: 0!important;
    .back-button {
      padding-left: 3.75rem!important;
      padding-right: 3.75rem!important;
    }
  }
}
