//
// Registration Page Styling
//
#create-name-row {
  margin-bottom: 0.5rem;
}
// Mobile
@include media-breakpoint-down(md){
  #create-last-name-label {
    margin-top: 1.75rem;
  }
  #create-name-row {
    margin-bottom: 1rem;
  }
}
