//
// Aside Menu Custom Styling
//
.custom-aside-menu-button {
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    
    p {
        color: #9495a6;
        margin-bottom: 0;
        margin-left: 0.9rem;
    }
    .chat-stack {
        margin-left: 2rem;
    }
}
.custom-aside-menu-button:hover {
    background-color: #1a1a27;
    cursor: pointer;
     p {
        color: #ffffff;
     }
     .menu-icon {
        color: var(--kt-primary)
     }
}
