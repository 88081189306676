//
// Footer Styling
//

.custom-footer {
  background-color: #f7f7f5;
  padding: 5rem 0 3rem;

  p {
    color: #767779;
    font-size: 1.25rem;
  }
  .logo-col {
    display: flex;
    align-items: center;
    margin-top: -2rem;
  }
}

// Mobile
@include media-breakpoint-down(md) {
  .custom-footer {
    padding-top: 3rem;
    .logo-col {
      .logo {
        margin-bottom: 2rem;
        height: 60px!important;
      }
    }
    .link-col {
      padding-left: 1.5rem;
    }
  }
}
