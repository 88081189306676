//
// Invitation Page Styling
//
.invitation-above-fold {
  padding-top: 6.5rem;
  background-color: #f7f7f5;

  .row {

    .left-col {
      background-color: #f7f7f5;
      height: 100%;
      padding: 3rem 10rem 3rem 10rem;

      h3 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 1rem;
        max-width: 45rem;
        line-height: 3rem;
        color: #100f0d;
      }
      form {
        padding-top: 2rem!important;
      }
      input {
        background-color: #ffffff;
        border: none;
      }
      input::placeholder {
        color: var(--kt-form-label-color);
      }
      label {
        width: 100%;
        margin-top: 1rem;
        display: flex;
      }
      p {
        align-self: center;
        height: 0.85rem;
        margin-left: 2rem;
        font-size: 1.25rem;
        color: #100f0d;
      }
      .btn-primary {
        background-color: #5276f7!important;
      }
    }
    .right-col {
      background-image: url('../../../../../public/media/backgrounds/laptop-image.png');
      min-height: 50rem;
      background-size: cover;
      background-position: bottom;
    }
  }
}

@include media-breakpoint-down(md) {
  .invitation-above-fold {
    .row {

      .left-col {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}
