//
// Pricing Page Styling
//
.pricing-page {
  .above-fold {
    background-color: #f7f7f5;
    padding: 10rem 5rem 5rem;

    h1 {
      text-align: center;
      margin: 0 auto 1.5rem;
    }
    .card-stack {
      align-items: flex-start;
      margin: 3rem auto 0;
      max-width: 95rem;

      .pricing-card {
        background-color: #ffffff;
        width: 32%;
        min-height: 55rem;
        border-radius: 0.25rem;
        -moz-box-shadow: 10px 10px 15px #d1d1d1;
        -webkit-box-shadow: 10px 10px 15px #d1d1d1;
        box-shadow: 10px 10px 15px #d1d1d1;
        position: relative;

        .card-header {
          background-color: #e2e4e3;
          height: 5rem;
          margin-top: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          h2 {
            font-size: 1.4rem;
            color: #1a1a1d;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .card-body {
          padding: 2rem;
          h3 {
            font-weight: 400;
            font-size: 1.35rem;
            color: #494a4b;
            margin: 0 auto;
            margin-bottom: 1.5rem;
            text-align: center;
            max-width: 25rem;
          }
          .starting-at {
            text-align: center;
            color: #535456;
            margin-bottom: 0.25rem;
          }
          .pricing-stack {
            justify-content: center;

            .original-price {
              color: #535456;
              text-decoration: line-through;
              margin-bottom: 0;
            }
            .new-price {
              font-size: 3rem;
              font-weight: 700;
              margin-bottom: 0;
              color: #100f0d;
            }
            .payment-schedule {
              color: #535456;
              margin-bottom: 0;
            }
          }
          .item-list {
            max-width: 25rem;
            margin: 0 auto;
          }
          .btn-primary {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2.5rem;
            margin-left: auto;
            margin-right: auto;
            width: 13.5rem;
            font-size: 1rem;
          }
        }
      }
      .center-card {
        width: 36%;
        min-height: 56rem;
        overflow: hidden;

        .corner-ribbon {
          position: absolute;
          top: 1rem;
          right: 0;
          height: 2.25rem;
          width: 9rem;
          transform: translate(27px, 3px) rotate(45deg);
          background-color: #ed1c24ff;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: #ffffff;
            font-size: 0.65rem;
            font-weight: 600;
            margin-bottom: 0;
          }  
        }
        .card-body {
          .starting-at {
            font-size: 1.2rem;
          }
          .pricing-stack {
            justify-content: center;

            .original-price {
              font-size: 1.2rem;
            }
            .new-price {
              font-size: 3.5rem;
            }
            .payment-schedule {
              font-size: 1.2rem;
            }
          }
          .item-list {
            max-width: 26rem;
          }
        }
      }
    }
  }
  .table-mobile {
    display: none;
  }
  .cs-capabilities-section {
    padding: 2rem 5rem;
    h2 {
      text-align: center;
      margin-bottom: 4rem;
    }
    .top-row {
      margin-bottom: 3.75rem;
    }
    .inner-stack {
      justify-content: center;
      h3 {
        margin-bottom: 0;
      }
      p {
        color: #494a4b;
        font-size: 1.25rem;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }
    .icon {
      width: 7rem;
    }
  }
  .full-width-card {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0.25rem;
    -moz-box-shadow: 10px 10px 15px #d1d1d1;
    -webkit-box-shadow: 10px 10px 15px #d1d1d1;
    box-shadow: 10px 10px 15px #d1d1d1;
    margin-top: 3rem;
    padding: 2rem;
  }
  .table.table-bordered {
    font-size: 1.15rem;
    -moz-box-shadow: 10px 10px 15px #d1d1d1;
    -webkit-box-shadow: 10px 10px 15px #d1d1d1;
    box-shadow: 10px 10px 15px #d1d1d1;
    .first-th {
      border-left: 1px solid var(--kt-gray-200)!important;
    }
    .last-th {
      border-right: 1px solid var(--kt-gray-200)!important;
    }
    th, td {
      height: 4.5rem;
    }
    th {
      border-bottom: 1px solid var(--kt-gray-200)!important;
      border-right: 1px solid white!important;
      background-color: #110f0d;
    }
    td {
      border: 1px solid var(--kt-gray-200)!important;
    }
  }
  .list-item {
    align-items: flex-start;
    justify-content: start;
    margin-top: 0.35rem;

    .check-icon {
      height: 1.5rem;
      margin-top: 0.25rem;
    }
    p {
      color: #494a4b;
      font-size: 1.15rem;
    }
  }
  // .additional-services-section {
  //   background-color: #ffffff;
  //   padding: 3rem 5rem;

  //    h2 {
  //     text-align: center;
  //     margin-top: 0;
  //    }
  //    .service-list {
  //     margin: 3rem auto 0;
  //     max-width: 95rem;
  //     .service-item {
  //       border: 1px solid #494a4b;
  //       padding: 1.5rem 2rem;
  //       border-radius: 0.25rem;
  //       margin-bottom: 2rem;

  //       .col-1 {
  //         .icon-text-stack {
  //           margin-top: 0.75rem;
  //           img {
  //             height: 2rem;
  //           }
  //           h3 {
  //             margin-bottom: 0;
  //           }
  //         }
  //         p {
  //           color: #494a4b;
  //           margin-top: 1rem;
  //           font-size: 1.1rem;
  //         }
  //       }
  //       .col-2 {
  //         margin-top: 0.75rem;
  //         ul {
  //           padding-left: 0;
  //           li {
  //             color: #494a4b;
  //             font-size: 1.25rem;
  //             margin-left: 1.5rem;            
  //           }
  //         }
  //       }
  //       .col-3 {
  //         display: flex;
  //         align-items: center;
  //         justify-content: end;
  //       }
  //     }
  //    }
  // }
  // .compare-features-section {
  //   padding: 0 5rem 7rem;
  //   h2 {
  //     text-align: center;
  //     margin-bottom: 4rem;
  //   }
    // .detail-card {
    //   width: 100%;
    //   border: 1px solid #e2e4e3;
    //   border-top: 2rem solid #e2e4e3;
    //   border-radius: 0.25rem;
    //   padding: 2rem 2rem 4rem 2rem;

    //   h3 {
    //     text-align: center;
    //   }
    //   h4 {
    //     color: #100f0d;
    //     margin-top: 4rem;
    //     margin-bottom: 1rem;
    //   }
    //   .hstack {
    //     min-height: 4.5rem;
    //     padding-top: 1rem;
    //     padding-bottom: 1rem;
    //     .circle-check-icon {
    //       height: 1.25rem;
  //       }
  //       p {
  //         margin-bottom: 0;
  //         color: #535456;
  //         font-size: 1.2rem;
  //       }
  //       .light-text {
  //         color: #d3d2d0;
  //       }
  //     }
  //     .bottom-dashed-stack {
  //       border-bottom: 2px dashed #e2e4e3;
  //     }
  //     .dbl-dashed-stack {
  //       border-top: 2px dashed #e2e4e3;
  //       border-bottom: 2px dashed #e2e4e3;
  //     }
  //   }
  // }
}

// Tablet
@include media-breakpoint-down(lg) {
  .pricing-page {
    .above-fold {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 5rem;
      .card-stack {
        .pricing-card {
          min-height: 60rem;
          .card-body {
            padding-left: 1rem!important;
            padding-right: 1rem!important;
          }
        }
        .center-card {
          min-height: 62rem;
        }
        .card-body {
          .pricing-stack {
            .new-price {
              font-size: 2.75rem!important;
            }
          }
        }
      }
    }
    .table.table-bordered {
      font-size: 0.9rem;
    }
    .cs-capabilities-section {
      padding: 2rem 2rem 0;
      h2 {
        margin-bottom: 2rem;
      }
      .top-row {
        margin-bottom: 0;
      }
      .col-lg-12 {
        margin-bottom: 1rem;
      }
    }
    .faq-section {
      padding-top: 0rem;
    }
    // .additional-services-section {
    //   padding-left: 2rem;
    //   padding-right: 2rem;
    //   .service-list {
    //     .service-item {
    //       .col-3 {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         .btn-primary {
    //           margin-top: 3rem
    //         }
    //       }
    //     }
    //   }
    // }
    // .compare-features-section {
    //   padding-left: 2rem;
    //   padding-right: 2rem;
    //   padding-bottom: 2rem;
    //   .detail-card {
    //     margin-bottom: 5rem;
    //   }
    // }
  }    
}

// Mobile
@include media-breakpoint-down(md){
  .pricing-page {
    .above-fold {
      padding-bottom: 3rem;
      .card-stack {
        flex-direction: column;
        .pricing-card {
          min-height: 53rem;
          width: 100%;
          margin-bottom: 2rem;
          .card-body {
            .item-list {
              max-width: 26rem;
            }
          }
        }
        .center-card {
          min-height: 48rem;
          .card-body {
            .pricing-stack {
              .new-price {
                font-size: 3.25rem;
              }
            }
          }
        }
      }
    }
    .full-width-card {
      max-width: 100%!important;
    }
    .table-desktop {
      display: none;
    }
    .table-mobile {
      display: block;
    }
    .cs-capabilities-section {
      h2 {
        margin-bottom: 3rem;
      }
      h3 {
        text-align: left;
      }
      .col-lg-12 {
        margin-bottom: 3rem;
      }
    }
    // .additional-services-section {
    //   padding-bottom: 1rem;
    //   .service-list {
    //     .service-item {
    //       padding-bottom: 3rem;
    //       .icon-text-stack{
    //         justify-content: center;
    //       }
    //       h3, p {
    //         text-align: center;
    //       }
    //       ul {
    //         margin-top: 2rem;
    //       }
    //       li {
    //         margin-top: 1rem;
    //       }
    //     }
    //   }
    // }
    // .compare-features-section {
    //   padding-left: 0;
    //   padding-right: 0;
    //   padding-bottom: 0;
    //   .detail-card {
    //     margin-bottom: 5rem;
    //   }
    // }
  }    
}
